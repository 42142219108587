import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import DisplayStore from './_DisplayStore'
import cardData from './../../assets/data/comoContratar.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import LeftRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'

import usePageQuery from '../../pageQuery'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Section, Card, Circle } from './style'

type itemsProps = {
  title: string;
  description: string;
}

type HeaderType = {
  setIsModalOpen: (state: boolean) => void;
}
interface ISeguroOdontoInter extends HeaderType {
  setDataLayer: (state: IDataLayerParams) => void;
}

const ComoContratar = ({ setIsModalOpen, setDataLayer }: ISeguroOdontoInter) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Como contratar?',
    element_action: 'click button',
    element_name: 'Contratar',
  }

  const rightArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )

  const leftArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <LeftRight width={40} height={40} color='orange--base' />
    </button>
  )
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 px-4 d-md-none'>
            <Img fluid={data.coberturasPlanoOdonto.fluid} alt='Mulher sorri enquanto contrata o Plano odontológico no Super App do Inter.' />
          </div>
          <div className='col-md-8 col-lg-12 pl-lg-3'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 mb-3 mt-4 text-grayscale--500 fw-600 font-sora'>
              Como contratar?
            </h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 mb-0 mb-lg-5 fw-400 text-grayscale--400'>
              O Plano Odontológico do Inter é exclusivo. Siga os passos pra abrir sua conta gratuita e ativar seu Plano.
            </p>
          </div>
          <div className='col-12 pb-4 pb-lg-0'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 40 }}
              md={{ items: 3 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}

            >
              {cardData.map((item: itemsProps, index: number) => (
                <div className='mr-2 mr-xl-3 px-0' key={index}>
                  <Card>
                    <div className='d-flex'>
                      <div>
                        <Circle>
                          {index + 1}
                        </Circle>
                      </div>
                      <h3 className='fs-16 lh-200 mb-0 ml-2 fw-600 text-grayscale--500 font-sora'>{item.title}</h3>
                    </div>
                    <p className='fs-14  lh-17 fw-400 text-grayscale--500 mt-4'>{item.description}</p>
                    {index === 0 &&
                      <DisplayStore />
                    }
                  </Card>
                </div>
              ))}
            </DefaultCarousel>
          </div>
          <div className='col-12 d-flex justify-content-center'>
            <button
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 ml-md-2 ml-lg-3 d-flex text-white text-none text-center'
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >
              Contratar agora
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoContratar
